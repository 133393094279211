import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Monetizer from "../components/monetizer"
import GridBlogList from "../components/Blog/GridBlogList"
import SEO from "../components/seo"

import "../styles/hub.css"

const StoriesPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Stories" />
      <div className="hub">
        <div className="hub-hero">
          <GatsbyImage
            className="hub-hero-pic"
            image={getImage(data.strapiStories.stories_hero.localFile)}
            loading="eager"
            placeholder="dominantColor"
            alt=""
          />
          <h1 className="hub-banner red">{data.strapiStories.title}</h1>
        </div>
        <div className="hub-main">
          <Monetizer />
          <GridBlogList />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    strapiStories {
      title
      stories_hero {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, quality: 100, formats: JPG)
          }
        }
      }
    }
  }
`

export default StoriesPage
