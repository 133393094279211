import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

import GridCard from "../GridCard"

import "../../styles/grid.css"

const GridBlogList = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiBlogPost(sort: { fields: publish_date, order: DESC }) {
        edges {
          node {
            title
            publish_date
            media {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED
                    quality: 100
                    aspectRatio: 1.7778
                  )
                }
              }
            }
            excerpt
          }
        }
      }
    }
  `)

  const cards = data.allStrapiBlogPost.edges.map(({ node }, index) => {
    const slug = `/stories/${node.title.replace(/\s/g, "_").replace(/\W/g, "")}`
    return (
      <GridCard
        key={index}
        cardImage={node.media.localFile}
        cardTitle={node.title}
        cardDate={node.publish_date}
        cardExcerpt={node.excerpt}
        slug={slug}
      />
    )
  })

  return <div className="grid-container">{cards}</div>
}

export default GridBlogList
